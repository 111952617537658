import React, { useEffect } from "react"
import styled from "styled-components"

const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  overflow: hidden;
`

const Title = styled.span`
  display: block;
  font-size: 11px;
  letter-spacing: 1px;
  color: var(--grey-dark);
  text-transform: uppercase;
`

const Ad = (props) => {
  useEffect(() => {
    if (window) (window.adsbygoogle = window.adsbygoogle || []).push({})
  })

  return (
    <Container>
      <Title>Reklama</Title>
      <ins
        className="adsbygoogle"
        data-ad-client="ca-pub-4454153225196817"
        data-ad-slot={props.slot}
        data-ad-format="auto"
        data-full-width-responsive="true"
        style={{ display: "block" }}
      />
    </Container>
  )
}

export default Ad
