import React from "react"
import styled from "styled-components"
import { Grid, Card, CardActionArea } from "@material-ui/core"

import ListingHeader from "./listing-header"
import SocialFacebook from "../images/social-facebook.svg"
import SocialYouTube from "../images/social-youtube.svg"

const Container = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(4)}px;
  `}
`

const SocialMedia = () => (
  <Container>
    <ListingHeader title="Socialiniai tinklai" />
    <Grid container spacing={2}>
      <Grid item xs={6} sm={4} md={3}>
        <Card>
          <CardActionArea
            component="a"
            href="https://www.facebook.com/%C5%A0e%C5%A1ko-klubas-406266606162962/"
          >
            <SocialFacebook />
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <Card>
          <CardActionArea
            component="a"
            href="https://www.youtube.com/channel/UCKsYYlqlHcBfCzL_h0-Y3lQ"
          >
            <SocialYouTube />
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  </Container>
)

export default SocialMedia
