import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Container } from "@material-ui/core"

import SEO from "../components/seo"
import Ad from "../components/ad"
import ArticleListing from "../components/article-listing"
import SocialMedia from "../components/social-media"

import { getArticlesByCategorySlug } from "../utils/article"

// These are taken from Google AdSense site
const GoogleAdSenseIds = [
  "6030658598",
  "4603146419",
  "9663901406",
  "3098493052",
  "2906921368",
  "9518956397",
]

const getAdByCategoryIndex = (categoryIndex) => {
  return <Ad slot={GoogleAdSenseIds[categoryIndex]} />
}

const Homepage = (props) => {
  const { Categories, Articles } = props.data
  const { nodes: CategoryList } = Categories
  const { nodes: ArticleList } = Articles
  return (
    <Container maxWidth="md">
      <SEO title="Naminis šeškas arba fretka" />
      {CategoryList.length > 0 &&
        CategoryList.map((category, index) => {
          const {
            frontmatter: { title, slug, description },
          } = category
          const articlesInCategory = getArticlesByCategorySlug(
            ArticleList,
            slug
          )
          return (
            <React.Fragment key={slug}>
              <ArticleListing
                title={title}
                description={description}
                articles={articlesInCategory}
              />
              {getAdByCategoryIndex(index)}
            </React.Fragment>
          )
        })}
      <SocialMedia />
    </Container>
  )
}

Homepage.propTypes = {
  data: PropTypes.object,
}

export default Homepage

export const pageQuery = graphql`
  query {
    Categories: allMarkdownRemark(
      filter: { fields: { sourceName: { eq: "categories" } } }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        frontmatter {
          title
          slug
          description
        }
      }
    }
    Articles: allMarkdownRemark(
      filter: { fields: { sourceName: { eq: "articles" } } }
    ) {
      nodes {
        frontmatter {
          slug
          title
          category
          thumbnail {
            childImageSharp {
              fluid(quality: 90, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    SomeStuff: allMarkdownRemark(
      filter: { fields: { sourceName: { eq: "articles" } } }
    ) {
      nodes {
        frontmatter {
          objectID: slug
          slug
          title
          category
        }
      }
    }
  }
`
